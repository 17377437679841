import moment from 'moment-timezone';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { selectStoreConfig } from '../../containers/Layout/selectors';
import { addNotSupportedSuburbs } from '../../containers/Order/actions';

import {
  ButtonUI,
  OrderItemUI,
  OrderUI,
} from '@next-order/next-order-components';
import { getItemPrice } from '@next-order/next-order-engine';
import { ReactComponent as IconTable } from 'assets/images/icon-table.svg';
import { eventNames } from 'configuration/eventNames';
import { MENU_ORDER_TYPE } from 'helpers/enums';
import { engineAddToHalfHalf } from 'helpers/order-engine';
import {
  ClockNineIcon,
  PickUpIcon,
  PlusRoundedThickIcon,
} from 'nexticons/outline';
import { firebaseAnalyticsNO } from '../../firebase';
import {
  getDeliveryCostFromApi,
  trimAddress,
} from '../../helpers/addressHelper';

import { MenuContext } from 'contexts/MenuProvider';
import { GeosuggestItemUI, GeosuggestUI } from 'UI';
import ChangeTimeActionButton from './ChangeTimeActionButton';
import ItemEditModal from './ItemEditModal';
import SelectTableActionButton from './SelectTableActionButton';
import { getCustomisationsForMenuItem } from 'containers/OrderTracker/getViewOrderData';
import debounce from 'lodash/debounce';
import {
  fetchVoucher,
  validateVoucher,
} from 'components/OrderReview/voucherHelper';
import getImage from 'helpers/getImage';

export default function OrderPreView({
  incrementItemQuantity,
  decrementItemQuantity,
  updateOrderDeliveryAddress,
  allSuburbs,

  cartState,
  setCartState,
  updateDeliveryPickupTime,

  toggleErrorStateForFutureTimeSelection,
  toggleErrorStateForTableSelection,
  dineInObjects,
  floors,
  addTableToOrder,
  isDineIn,
  removeItemFromOrder,
  onClickItemHandler,
  deliveryAddressMissing,
  updateVoucher,
  getDiscountedAmount,
  removeVoucher,
}) {
  const geoSuggestRef = useRef(null);
  const storeConfig = useSelector(selectStoreConfig);
  const halfHalfSetup = useSelector(
    (state) => state.layoutReducer.halfHalfSetup
  );
  const { userDetails, orderSetup, currentOrder } = useSelector(
    (state) => state.homeReducer
  );

  const { selectedMenuItems } = useContext(MenuContext);

  const [state, setLocalState] = useReducer((s, a) => ({ ...s, ...a }), {
    start: false,
    selectedTable: null,
    errorMessage: '',
  });
  const [editItem, setEditItem] = useState(null);
  const [voucherInput, setVoucherInput] = useState('');
  const [voucherState, setVoucherState] = useState({
    selectedVoucher: '',
    state: '',
    voucherErrorMessage: '',
    isVoucherTrue: false,
    voucher: false,
  });

  const isPickupOpen = cartState.currentShift
    ? cartState.currentShift.isPickupOpen
    : false;

  const isDeliveryOpen = cartState.currentShift
    ? cartState.currentShift.isDeliveryOpen
    : false;

  const selectTime = useCallback(
    (timeObj) => {
      firebaseAnalyticsNO.logEvent(eventNames.SELECTED_TIME_OF_COLLECTION);

      let deliveryDate = moment(
        `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
        'YYYY-MM-DD HH:mm A'
      );
      if (timeObj.alternateDisplay === 'ASAP')
        updateDeliveryPickupTime(deliveryDate, timeObj.shiftId, true, 0, false);
      else
        updateDeliveryPickupTime(deliveryDate, timeObj.shiftId, false, 0, true);

      toggleErrorStateForFutureTimeSelection(false);
    },
    [toggleErrorStateForFutureTimeSelection, updateDeliveryPickupTime]
  );

  const updateSelectedTimeIfItIsInPast = useCallback(
    (order, availableTimes) => {
      if (order && order.deliveryDate && availableTimes && orderSetup) {
        let today = moment(
          moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
          'YYYY-MM-DD HH:mm'
        );
        if (order.orderType === MENU_ORDER_TYPE.pickup)
          today.add(
            orderSetup && orderSetup.pickUpTime
              ? Number(orderSetup.pickUpTime)
              : 0,
            'minutes'
          );
        else
          today.add(
            orderSetup && orderSetup.deliveryTime
              ? Number(orderSetup.deliveryTime)
              : 0,
            'minutes'
          );
        let delDate = moment(order.deliveryDate, 'YYYY-MM-DD HH:mm');

        //Detect if update required
        if (today.isAfter(delDate)) {
          if (
            availableTimes.length > 0 &&
            availableTimes[0].times &&
            availableTimes[0].times.length > 0
          ) {
            let timeObj = availableTimes[0].times[0];
            let deliveryDate = moment(
              `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
              'YYYY-MM-DD HH:mm A'
            );
            if (deliveryDate.isAfter(today)) {
              updateDeliveryPickupTime(
                deliveryDate,
                timeObj.shiftId,
                false,
                0,
                true
              );
            } else {
              updateDeliveryPickupTime(today, timeObj.shiftId, false, 0, false);
            }
          } else {
            // No time slot available
          }
        }
      } else if (
        order &&
        !order.deliveryDate &&
        availableTimes &&
        availableTimes[0] &&
        orderSetup
      ) {
        const asapTime = availableTimes[0].times[0];
        selectTime(asapTime);
      }
    },
    [orderSetup, selectTime, storeConfig.timeZone, updateDeliveryPickupTime]
  );

  const handleFetchDineInObjects = async () => {
    if (!dineInObjects?.length) return;
    const number = currentOrder?.tableNumber;
    const floorLayoutId = currentOrder?.floorLayoutId;
    const dineInObject = dineInObjects.find(
      (item) =>
        (item.name === number || item.number === number) &&
        item.floorLayoutId === floorLayoutId
    );
    setLocalState({
      selectedTable: dineInObject,
    });
  };

  useEffect(() => {
    if (!state.selectedTable && dineInObjects?.length > 0) {
      handleFetchDineInObjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentOrder), JSON.stringify(dineInObjects)]);

  useEffect(() => {
    if (deliveryAddressMissing) {
      setLocalState({
        deliveryAddrError: true,
        errorMessage: 'Please enter delivery address.',
      });
    }
  }, [deliveryAddressMissing]);

  useEffect(() => {
    if (state.orderType !== currentOrder?.orderType) {
      setLocalState({
        deliveryAddrError: false,
        errorMessage: '',
      });
    }
    if (
      state.orderType !== currentOrder?.orderType ||
      !currentOrder?.deliveryDate
    ) {
      setLocalState({ orderType: currentOrder?.orderType });
      updateSelectedTimeIfItIsInPast(
        currentOrder,
        currentOrder.orderType === MENU_ORDER_TYPE.delivery
          ? cartState.deliveryTimes
          : cartState.pickUpTimes
      );
    }
  }, [
    currentOrder,
    cartState.deliveryTimes,
    cartState.pickUpTimes,
    state.orderType,
    updateSelectedTimeIfItIsInPast,
  ]);

  const getGeoSuggestRenderItem = (suggestedAddress) => {
    const address =
      suggestedAddress?.label ||
      trimAddress({ mainAddress: suggestedAddress.description, storeConfig });

    return <GeosuggestItemUI>{address}</GeosuggestItemUI>;
  };

  const setSelectedTable = (table) => {
    setLocalState({
      selectedTable: table,
    });

    addTableToOrder(table);
    toggleErrorStateForTableSelection(false);
  };

  const updateDeliveryAddress = async (addObj) => {
    if (typeof addObj === 'undefined') {
      setLocalState({ start: false });
      return;
    }

    const { useRadiusBasedDeliveryAreaCalulation = false } = orderSetup ?? {};

    let sub = '',
      area = '',
      areaCode = '';
    const areasToMatchArray = [];
    if (addObj?.gmaps?.address_components?.length > 0) {
      addObj.gmaps.address_components.forEach((addressComponent) => {
        if (addressComponent?.types) {
          for (let i = 0; i < addressComponent.types.length; i++) {
            const type = addressComponent.types[i];
            if (type === 'postal_code') {
              areaCode = addressComponent.long_name
                ? addressComponent.long_name
                : '';
            }
            if (
              type === 'locality' ||
              type === 'route' ||
              type === 'postal_town' ||
              type === 'sublocality_level_1'
            ) {
              areasToMatchArray.push(addressComponent.long_name);
              break;
            }
          }
        }
      });
    }
    if (useRadiusBasedDeliveryAreaCalulation) {
      const { isDeliverable, deliveryCost, minOrderValue } =
        await getDeliveryCostFromApi(storeConfig.storeId, addObj.location);

      if (!isDeliverable) {
        updateOrderDeliveryAddress('', '', areaCode, areasToMatchArray[0]);
        setLocalState({
          deliveryAddrError: true,
          errorMessage: 'Your location is not currently supported.',
        });
        return;
      }

      setLocalState({ deliveryAddrError: false, errorMessage: '' });
      updateOrderDeliveryAddress(
        addObj,
        { suburbId: '', deliveryCost, minOrderValue },
        areaCode,
        areasToMatchArray[0]
      );
      geoSuggestRef.current?.blur();
      return;
    }

    if (allSuburbs?.length > 0) {
      let supportedSubs = allSuburbs.find((s) => {
        const sName = s?.name?.toLowerCase()?.trim();

        const areaName = areasToMatchArray[0]?.toLowerCase().trim();
        if (typeof areaName === 'string') {
          const doesMatch = sName === areaName;
          if (doesMatch) {
            area = areasToMatchArray[0];
            return s;
          }
        }
        return false;
      });

      if (supportedSubs) {
        supportedSubs = [supportedSubs];
      }

      if (!supportedSubs || supportedSubs?.length <= 0) {
        supportedSubs = allSuburbs.filter((s) => {
          const sName = s?.name?.toLowerCase()?.trim();
          const sNamesArray = sName
            ?.split(',')
            ?.filter((s) => s)
            ?.map((s) => s?.trim());

          for (let i = 0; i < areasToMatchArray?.length; i++) {
            const areaName = areasToMatchArray[i]?.toLowerCase().trim();
            if (typeof areaName === 'string') {
              const doesMatch = sName === areaName;
              if (doesMatch) {
                area = areasToMatchArray[i];
                return true;
              }
            }
          }

          for (let i = 0; i < areasToMatchArray?.length; i++) {
            const areaName = areasToMatchArray[i]?.toLowerCase().trim();
            if (typeof areaName === 'string') {
              const doesMatch = sNamesArray?.includes(areaName);
              if (doesMatch) {
                area = areasToMatchArray[i];
                return true;
              }
            }
          }

          return false;
        });
      }

      if (supportedSubs && supportedSubs.length > 0) {
        geoSuggestRef.current?.blur();
        setLocalState({ deliveryAddrError: false, errorMessage: '' });
        updateOrderDeliveryAddress(addObj, supportedSubs[0], areaCode, area);
        return;
      }

      updateOrderDeliveryAddress('', '', areaCode, area);
      setLocalState({
        deliveryAddrError: true,
        errorMessage: 'Your location is not currently supported.',
      });
      let username = null;
      let mobileNumber = null;
      let userId = null;
      if (userDetails) {
        if (userDetails.firstName && userDetails.lastName) {
          username = userDetails.firstName + ' ' + userDetails.lastName;
        }
        if (userDetails.mobileNumber) {
          mobileNumber = userDetails.mobileNumber;
        }
        if (userDetails.userId) {
          userId = userDetails.userId;
        }
      }
      if (addObj && sub && username && mobileNumber && userId) {
        addNotSupportedSuburbs(
          storeConfig,
          addObj,
          sub,
          username,
          mobileNumber,
          userId
        );
      }
    }

    // IF THERE ARE NO SUPPORTED SUBURBS
    updateOrderDeliveryAddress('', '', '', '');
    setLocalState({
      deliveryAddrError: true,
      errorMessage: 'Your location is not currently supported.',
    });

    let username = null;
    let mobileNumber = null;
    let userId = null;
    if (userDetails) {
      if (userDetails.firstName && userDetails.lastName) {
        username = userDetails.firstName + ' ' + userDetails.lastName;
      }
      if (userDetails.mobileNumber) {
        mobileNumber = userDetails.mobileNumber;
      }
      if (userDetails.userId) {
        userId = userDetails.userId;
      }
    }

    if (addObj && sub && username && mobileNumber && userId) {
      addNotSupportedSuburbs(
        storeConfig,
        addObj,
        sub,
        username,
        mobileNumber,
        userId
      );
    }
  };

  const getLocalItemPrice = (itemObj) => {
    let itemPrice = Number(getItemPrice(itemObj, halfHalfSetup));
    const rowMenuItems = JSON.parse(JSON.stringify(currentOrder.menuItems));
    if (itemObj && itemObj?.isHalf && halfHalfSetup) {
      const otherHalf = itemObj.whichHalf === 1 ? 2 : 1;
      const othersHalf = rowMenuItems.filter((m) => {
        return m.halfIndex === itemObj.halfIndex && m.whichHalf === otherHalf;
      });
      const selectedHalfItems = [itemObj, othersHalf[0]];
      const itemsPresent = engineAddToHalfHalf(
        itemObj,
        {
          selectedHalfItems,
          whichHalf: itemObj.whichHalf,
        },
        { halfHalfSetup: halfHalfSetup || {} }
      );

      itemPrice = itemsPresent?.reduce(
        (acc, m) => (acc += parseFloat(getItemPrice(m || {}, halfHalfSetup))),
        0
      );
    }
    return itemPrice.toFixed(2);
  };

  const getNextFutureTimeDelivery = () => {
    if (cartState.deliveryTimes && cartState.deliveryTimes.length > 0) {
      let timeObj = cartState.deliveryTimes[0].times[0];
      let deliveryDate = moment(
        `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
        'YYYY-MM-DD HH:mm A'
      );
      return deliveryDate;
    }
  };

  const getNextFutureTimePickup = () => {
    if (cartState.pickUpTimes && cartState.pickUpTimes.length > 0) {
      let timeObj = cartState.pickUpTimes[0].times[0];
      let deliveryDate = moment(
        `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
        'YYYY-MM-DD HH:mm A'
      );
      return deliveryDate;
    }
  };

  const getTimeToDisplayDayPick = () => {
    // No time slot available
    if (cartState.pickUpTimes) {
      let today = moment(
        moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
        'YYYY-MM-DD hh:mm A'
      );

      // if user has already choosen time slot
      if (currentOrder?.deliveryDate) {
        let delDate = moment(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm A');
        if (delDate.isSame(today, 'day')) {
          return `Today`;
        } else if (delDate.isSame(today.add(1, 'day'), 'day')) {
          return `Tomorrow`;
        } else return delDate.format('dddd');
      } else if (isPickupOpen) {
        return 'Pickup';
      } else {
        var day = today.weekday() === 0 ? '7' : today.weekday().toString();
        let slotsForTheDay = cartState.pickUpTimes.filter((slotObj) => {
          return slotObj.day === day;
        });

        // If slots are available for a day
        if (slotsForTheDay.length > 0) {
          let delDate = moment(getNextFutureTimePickup());
          if (delDate.isSame(today, 'day')) {
            return `Today`;
          } else if (delDate.isSame(today.add(1, 'day'), 'day')) {
            return `Tomorrow`;
          } else return delDate.format('dddd');
        } else {
          return 'Closed';
        }
      }
    } else {
      return 'Pickup';
    }
  };

  const getTimeToDisplayTimePick = () => {
    const timeToDisplay =
      orderSetup?.pickUpTime || orderSetup?.defaultPickUpTime || 15;

    let timePickObj = {
      isDisplay: true,
      timeToDisplay: `${timeToDisplay} minutes`,
    };

    if (!cartState.pickUpTimes || !orderSetup) {
      return timePickObj;
    }

    let today = moment(
      moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
      'YYYY-MM-DD hh:mm A'
    );

    var day = today.format(`E`);

    let slotsForTheDay = cartState.pickUpTimes.filter((slotObj) => {
      return slotObj.day === day;
    });

    // If slots are available for a day
    if (
      (slotsForTheDay.length > 0 &&
        orderSetup &&
        !orderSetup.isPreOrderingEnabled) ||
      (orderSetup &&
        orderSetup.isPreOrderingEnabled &&
        cartState.pickUpTimes.length > 0)
    ) {
      if (
        currentOrder &&
        currentOrder.deliveryDate &&
        currentOrder.isFutureDeliveryDate
      ) {
        timePickObj.timeToDisplay = moment(
          currentOrder.deliveryDate,
          'YYYY-MM-DD HH:mm A'
        ).format('hh:mm A');
        return timePickObj;
      } else if (isPickupOpen && orderSetup) {
        if (parseInt(orderSetup.pickUpTime) < 60) {
          timePickObj.timeToDisplay = `${orderSetup.pickUpTime} Minutes`;
          return timePickObj;
        } else {
          let minutesT = parseInt(orderSetup.pickUpTime);
          var hours = Math.trunc(minutesT / 60);
          var minutes = minutesT % 60;
          if (minutes === 0) {
            timePickObj.timeToDisplay = `${hours} ${
              hours > 1 ? 'hours' : 'hour'
            }`;
            return timePickObj;
          } else {
            timePickObj.timeToDisplay = `${hours}h : ${minutes}m`;
            return timePickObj;
          }
        }
      } else {
        timePickObj.timeToDisplay = 'Select Time';
        return timePickObj;
      }
    } else {
      timePickObj.isDisplay = false;
      timePickObj.timeToDisplay = `Reopen`;
      return timePickObj;
    }
  };

  const getTimeToDisplayDayDel = () => {
    // No time slot available
    if (cartState.deliveryTimes) {
      let today = moment(
        moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
        'YYYY-MM-DD hh:mm A'
      );
      // if user has already choosen time slot
      if (currentOrder?.deliveryDate) {
        let delDate = moment(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm A');
        if (delDate.isSame(today, 'day')) {
          return `Today`;
        } else if (delDate.isSame(today.add(1, 'day'), 'day')) {
          return `Tomorrow`;
        } else return delDate.format('dddd');
      } else if (isDeliveryOpen) {
        return 'Delivery';
      } else {
        var day = today.weekday() === 0 ? '7' : today.weekday().toString();
        let slotsForTheDay = cartState.deliveryTimes.filter((slotObj) => {
          return slotObj.day === day;
        });

        // If slots are available for a day
        if (slotsForTheDay.length > 0) {
          let delDate = moment(getNextFutureTimeDelivery());
          if (delDate.isSame(today, 'day')) {
            return `Today`;
          } else if (delDate.isSame(today.add(1, 'day'), 'day')) {
            return `Tomorrow`;
          } else return delDate.format('dddd');
        } else {
          return 'Closed';
        }
      }
    } else {
      return 'Delivery';
    }
  };

  const getTimeToDisplayTimeDel = () => {
    const timeToDisplay =
      orderSetup?.deliveryTime || orderSetup?.defaultDeliveryTime || 60;

    let timeDelObj = {
      isDisplay: true,
      timeToDisplay: `${timeToDisplay} minutes`,
    };

    if (!cartState.deliveryTimes || !orderSetup) {
      return timeDelObj;
    }

    let today = moment(
      moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
      'YYYY-MM-DD hh:mm A'
    );

    var day = today.format(`E`);

    let slotsForTheDay = cartState.deliveryTimes.filter((slotObj) => {
      return slotObj.day === day;
    });

    // If slots are available for a day
    if (
      (slotsForTheDay.length > 0 &&
        orderSetup &&
        !orderSetup.isPreOrderingEnabled) ||
      (orderSetup &&
        orderSetup.isPreOrderingEnabled &&
        cartState.deliveryTimes.length > 0)
    ) {
      if (
        currentOrder &&
        currentOrder.deliveryDate &&
        currentOrder.isFutureDeliveryDate
      ) {
        timeDelObj.timeToDisplay = moment(
          currentOrder.deliveryDate,
          'YYYY-MM-DD HH:mm A'
        ).format('hh:mm A');
        return timeDelObj;
      } else if (isDeliveryOpen && orderSetup) {
        if (parseInt(orderSetup.deliveryTime) < 60) {
          timeDelObj.timeToDisplay = `${orderSetup.deliveryTime} Minutes`;
          return timeDelObj;
        } else {
          let minutesT = parseInt(orderSetup.deliveryTime);
          var hours = Math.trunc(minutesT / 60);
          var minutes = minutesT % 60;
          if (minutes === 0) {
            timeDelObj.timeToDisplay = `${hours} ${
              hours > 1 ? 'hours' : 'hour'
            }`;
            return timeDelObj;
          } else {
            timeDelObj.timeToDisplay = `${hours}h : ${minutes}m`;
            return timeDelObj;
          }
        }
      } else {
        timeDelObj.timeToDisplay = 'Select Time';
        return timeDelObj;
      }
    } else {
      timeDelObj.isDisplay = false;
      timeDelObj.timeToDisplay = `Reopen`;
      return timeDelObj;
    }
  };

  const clearVoucher = () => {
    setVoucherInput('');
    setVoucherState({
      ...voucherState,
      state: '',
    });
  };

  const enter = (e) => {
    if (e.keyCode === 13 && state.voucherState === 'match') {
      updateVoucher(state.selectedVoucher);
      setVoucherState({
        ...voucherState,
        voucher: false,
        state: '',
      });
    }
  };

  const addVoucher = () => {
    setVoucherInput('');
    setVoucherState({
      ...voucherState,
      voucher: !voucherState.voucher,
      voucherErrorMessage: '',
      state: '',
    });
  };

  const getDiscountedAmountPrice = (selectedVoucher) => {
    if (currentOrder && selectedVoucher) {
      if (selectedVoucher.voucherType === '1')
        if (currentOrder.voucherId && currentOrder.voucherDiscount) {
          let dscAmt = (
            ((Number(currentOrder.payableAmount) +
              Number(currentOrder.voucherDiscount)) *
              Number(selectedVoucher.discount)) /
            100
          ).toFixed(2);
          if (
            Number(currentOrder.payableAmount) +
              Number(currentOrder.voucherDiscount) >
            dscAmt
          )
            return dscAmt;
          else
            return (
              Number(currentOrder.payableAmount) +
              Number(currentOrder.voucherDiscount)
            );
        } else {
          let dscAmt = (
            (Number(currentOrder.payableAmount) *
              Number(selectedVoucher.discount)) /
            100
          ).toFixed(2);
          if (currentOrder.payableAmount > dscAmt) return dscAmt;
          else return currentOrder.payableAmount;
        }
      else {
        if (currentOrder.voucherId && currentOrder.voucherDiscount) {
          let dscAmt = selectedVoucher.discount;
          if (
            Number(currentOrder.payableAmount) +
              Number(currentOrder.voucherDiscount) >
            dscAmt
          )
            return dscAmt;
          else
            return (
              Number(currentOrder.payableAmount) +
              Number(currentOrder.voucherDiscount)
            );
        } else {
          let dscAmt = Number(selectedVoucher.discount);
          if (currentOrder.payableAmount > dscAmt) return dscAmt;
          else return currentOrder.payableAmount;
        }
      }
    }
    return 0;
  };

  const updateVoucherState = (voucher) => {
    setVoucherState({
      ...voucherState,
      voucher: false,
      state: '',
    });
    updateVoucher(voucher);
  };

  const fetchVoucherDetails = debounce((text) => {
    let validateString = text.toUpperCase();
    if (validateString.length >= 3) {
      fetchVoucher(storeConfig, {
        storeId: storeConfig.storeId,
        voucherCode: validateString,
      }).then((result) => {
        if (result.success === true) {
          setVoucherState({
            ...voucherState,
            isVoucherTrue: true,
          });
          let selectedVoucher = result.voucher;
          let response = validateVoucher(
            storeConfig,
            selectedVoucher,
            currentOrder,
            currentOrder.payableAmount,
            userDetails
          );
          if (response.success) {
            if (selectedVoucher.type !== '3') {
              selectedVoucher.voucherDiscount = Number(
                getDiscountedAmountPrice(selectedVoucher)
              ).toFixed(2);
              setVoucherState({
                ...voucherState,
                state: 'match',
                selectedVoucher,
              });
              updateVoucherState(selectedVoucher);
            } else {
              let discountObj = getDiscountedAmount(selectedVoucher);
              // if (discountObj.discount !== 0) {
              selectedVoucher.voucherDiscount = Number(
                getDiscountedAmountPrice(selectedVoucher)
              ).toFixed(2);
              setVoucherState({
                ...voucherState,
                state: 'match',
                selectedVoucher,
              });
              updateVoucherState(selectedVoucher);
              // } else {
              // Case When Voucher is Expired
              //   setVoucherState({
              //     ...voucherState,
              //     state: 'nomatch',
              //     selectedVoucher,
              //     voucherErrorMessage: `Discounted items for voucher are not present in cart or already discounted with a special`,
              //   });
              // }
            }
          } else {
            setVoucherState({
              ...voucherState,
              state: 'nomatch',
              selectedVoucher: selectedVoucher,
              voucherErrorMessage: response.voucherErrorMessage,
            });
          }
        } else {
          if (voucherState.isVoucherTrue !== true) {
            setVoucherState({
              ...voucherState,
              state: 'nomatch',
              selectedVoucher: {},
              voucherErrorMessage: '',
            });
          }
        }
      });
    } else {
      setVoucherState({
        ...voucherState,
        state: '',
        voucherErrorMessage: '',
        isVoucherTrue: false,
      });
    }
  }, 300);

  const onVoucherChange = (e) => {
    e.persist();
    setVoucherInput(e.target.value);
    setVoucherState({
      ...voucherState,
      state: 'loading',
      isVoucherTrue: false,
    });
    fetchVoucherDetails(e.target.value);
  };

  const removeVoucherHandler = (e) => {
    setVoucherState({
      selectedVoucher: '',
      state: '',
      voucherErrorMessage: '',
      isVoucherTrue: false,
      voucher: false,
    });
    setVoucherInput('');
    removeVoucher();
  };

  const { selectedTable } = state;

  let storeLocation =
    window?.google?.maps && storeConfig?.addressLocation
      ? new window.google.maps.LatLng(
          storeConfig.addressLocation.lat,
          storeConfig.addressLocation.long
        )
      : {};

  const pickupTimePicObj = getTimeToDisplayTimePick();
  const deliveryTimePicObj = getTimeToDisplayTimeDel();

  const table = selectedTable?.name || selectedTable?.number;

  return (
    <React.Fragment key='.0'>
      {currentOrder.orderType === MENU_ORDER_TYPE.pickup && !isDineIn ? (
        <OrderUI.Box>
          <OrderUI.BoxItem
            desc={` ${storeConfig.address}`}
            title={storeConfig.name}
            icon={
              <PickUpIcon className='sm:w-5.5' strokeWidth={2} width={24} />
            }
          />

          <OrderUI.BoxItem
            extendedClassName='!pr-0'
            actionBtn={
              <ChangeTimeActionButton
                timesArr={cartState.pickUpTimes}
                selectTime={selectTime}
                type={'Pickup'}
                defaultDateStr={currentOrder.deliveryDate}
                cartState={cartState}
                setCartState={setCartState}
                updateDeliveryPickupTime={updateDeliveryPickupTime}
              />
            }
            desc={`${getTimeToDisplayDayPick()} - ${
              pickupTimePicObj.timeToDisplay
            }`}
            icon={
              <ClockNineIcon className='sm:w-5.5' strokeWidth={2} width={24} />
            }
            title='Pickup Time'
          />
        </OrderUI.Box>
      ) : currentOrder.orderType === MENU_ORDER_TYPE.delivery && !isDineIn ? (
        <OrderUI.Box>
          <GeosuggestUI
            ref={geoSuggestRef}
            currentOrder={currentOrder}
            address={currentOrder.address}
            menuItemsLength={currentOrder.menuItems.length}
            deliveryCost={currentOrder.deliveryCost}
            currency={storeConfig.currency}
            deliveryAddrError={state.deliveryAddrError}
            country={`${storeConfig.countryIdentifier}`}
            onKeyDown={(e) => {
              if (e.keyCode === 9) {
                e.preventDefault();
                return;
              }
            }}
            onChange={(_value) => {
              updateOrderDeliveryAddress('', '', '', '');
            }}
            type='search'
            location={storeLocation}
            radius={5000}
            initialValue={currentOrder.address && currentOrder.address}
            className='deliverySearchBox'
            placeholder='Enter delivery address'
            minLength={3}
            highlightMatch={true}
            renderSuggestItem={getGeoSuggestRenderItem}
            onSuggestNoResults={() => setLocalState({ start: false })}
            onSuggestSelect={(addrs) => updateDeliveryAddress(addrs)}
            getSuggestLabel={(suggest) => {
              const isCustomAddress =
                suggest?.structured_formatting?.main_text &&
                suggest?.structured_formatting?.secondary_text;
              return trimAddress({
                mainAddress: isCustomAddress
                  ? suggest?.structured_formatting?.main_text
                  : suggest?.description,
                secondryAddress: suggest?.structured_formatting?.secondary_text,
                storeConfig,
              })?.trim();
            }}
            errorMessage={state.errorMessage}
          />

          <OrderUI.BoxItem
            extendedClassName='!pr-0'
            actionBtn={
              <ChangeTimeActionButton
                timesArr={cartState.deliveryTimes}
                selectTime={selectTime}
                type={'Delivery'}
                defaultDateStr={currentOrder.deliveryDate}
                cartState={cartState}
                setCartState={setCartState}
                updateDeliveryPickupTime={updateDeliveryPickupTime}
              />
            }
            desc={`${getTimeToDisplayDayDel()} - ${
              deliveryTimePicObj.timeToDisplay
            }`}
            icon={
              <ClockNineIcon className='sm:w-5.5' strokeWidth={2} width={24} />
            }
            title='Delivery Time '
          />
        </OrderUI.Box>
      ) : currentOrder.orderType === MENU_ORDER_TYPE.dinein ? (
        <OrderUI.Box>
          <OrderUI.BoxItem
            extendedClassName='!pr-0'
            actionBtn={
              <SelectTableActionButton
                floors={floors}
                selectedTable={selectedTable}
                dineInObjects={dineInObjects}
                setSelectedTable={setSelectedTable}
              />
            }
            desc={table ? `Table ${table}` : 'Select Table'}
            icon={<IconTable className='sm:w-5.5' strokeWidth={2} width={24} />}
            title=''
          />
        </OrderUI.Box>
      ) : null}

      <OrderUI.Group
        actionsGroup={
          <>
            {!currentOrder.voucherId && (
              <ButtonUI
                extendedClassName='!border-0 shadow-modifier-badge'
                fontSize='sm'
                rounded='full'
                variant='outlined'
                leftIcon={
                  voucherState.voucher ? null : (
                    <PlusRoundedThickIcon strokeWidth={3} width={18} />
                  )
                }
                onClick={addVoucher}
              >
                {voucherState.voucher ? 'Cancel' : 'Add Voucher'}
              </ButtonUI>
            )}
          </>
        }
        title='Items'
      >
        <>
          <div
            className={
              voucherState.voucher && !currentOrder.voucherId
                ? 'voucherBoxCart active'
                : 'voucherBox'
            }
          >
            <input
              autoComplete='off'
              onKeyUp={(e) => enter(e)}
              className={
                currentOrder.voucherId &&
                currentOrder.voucherId !== '' &&
                voucherInput === currentOrder.voucherName
                  ? `voucherInp match`
                  : `voucherInp ${voucherState.state} `
              }
              name='voucher'
              onChange={onVoucherChange}
              value={voucherInput}
              placeholder='Enter voucher code'
            />
            <span>
              {voucherState.state === 'loading' ? (
                <img
                  src={`${window.storeConfigInitial?.storageBaseUrl}/assets/loading.svg`}
                  alt=''
                />
              ) : (
                voucherState.state === 'nomatch' && (
                  <i onClick={() => clearVoucher()} className='thickcon-X' />
                )
              )}
            </span>
          </div>
          {voucherState.state === 'nomatch' &&
          voucherState.voucherErrorMessage ? (
            <div className='codeSuccess match'>
              <p>
                <span>{voucherState.voucherErrorMessage}</span>
              </p>
            </div>
          ) : (
            <div className={'codeSuccess'} />
          )}
          {currentOrder.voucherId && (
            <div className='text-4.25 my-2 voucherApplied'>
              <div className='voucherDetails'>
                <div className='voucherName'>{currentOrder.voucherName}</div>
                <div className='sm:text-3.25'>
                  Saved <b>${currentOrder.voucherDiscount}</b> on the voucher
                </div>
              </div>
              <div className='voucherRemove' onClick={removeVoucherHandler}>
                REMOVE
              </div>
            </div>
          )}
          {selectedMenuItems.map((item, index) => {
            const chosenSize = item.selectedSizes?.[0] ?? {};
            const { itemData, add, remove } =
              getCustomisationsForMenuItem(item);
            let description = [];

            if (itemData?.size) {
              description.push(`${itemData.size}`);
            }
            if (itemData?.variant) {
              description.push(`${itemData.variant}`);
            }
            if (add) {
              description.push(`Add ${add}`);
            }
            if (remove) {
              description.push(`Remove ${remove}`);
            }
            description = description.join(', ');
            return (
              <OrderItemUI
                key={index}
                desc={description}
                imgSrc={getImage(item.mediaPath || item.urlS3)}
                price={`${storeConfig.currency}${getLocalItemPrice(item)}`}
                quantity={
                  item.isHalf ? chosenSize.quantity * 2 : chosenSize.quantity
                }
                title={
                  item.isHalf ? `${item.name} / ${item.nameHalf}` : item.name
                }
                decrement={() => decrementItemQuantity(chosenSize, item)}
                increment={() => incrementItemQuantity(chosenSize, item)}
                onClickEdit={() => setEditItem(item)}
              />
            );
          })}
        </>
      </OrderUI.Group>
      <ItemEditModal
        selectedMenuItems={selectedMenuItems}
        editItem={editItem}
        setEditItem={setEditItem}
        removeItemFromOrder={removeItemFromOrder}
        incrementItemQuantity={incrementItemQuantity}
        decrementItemQuantity={decrementItemQuantity}
        currency={storeConfig.currency}
        getLocalItemPrice={getLocalItemPrice}
        onClickItemHandler={onClickItemHandler}
      />
    </React.Fragment>
  );
}
